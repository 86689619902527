.selected{
  background: #800080!important;
}
.options{
  margin-top: 10px;
  padding: 0;
  border: 3px solid #000;
  cursor: pointer;
  width: 100%;
  background-color: white;
  color: white;
  font-weight: bold;
}
ul{
  list-style-type: none;
}
ul li{
  margin: 5px;
}
.quiz-page{

  position: relative;
  background-color: #C7F3FF;
  height: auto;

  margin-bottom: 20px;
}
.quiz-div{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color:#000;
  font-weight: 600;
  font-family: 'Segoe UI',;
  font-size: 18px;
  background-color: #C7F3FF;
  height: 100%;
}
.quiz-div h2{
  text-align: center;
  margin: 0 auto;
  font-size: 20px;
  text-shadow: 1px 1px black;
  max-width: 90%; 
}
.quiz-div span{
  margin-bottom: 35px;

  text-shadow: 1px 1px black;

}
.quiz-div p{
  padding: 15px;
  text-align: center;
  width: 40%;
  border: 0 solid white;
  border-radius: 60px;
  background-color: #0398DA;
  box-shadow: 2px 2px 2px 1px white;
  text-shadow: 1px 1px black;
}
.quiz-div button{
  padding: 8px;
  border: 0 solid white;
  border-radius: 10px;
  box-shadow: 2px 2px 2px 1px white;
  margin-bottom: 30px;
  margin-top: 20px;
  background: #01bf71;
  color: #fff;
  width: 16%;
  height: 38px;
}
.quiz-div-end{
  
  display: flex;
  justify-content: top;
  align-items: center;
  flex-direction: column;
  color:White;
  font-weight: 600;
  font-family: 'Segoe UI',;
  font-size: 18px;
  background-color: #C7F3FF;
  height: 100%;
}
.quiz-div-end h1{
  margin-top: 50px;
  text-shadow: 1px 1px black;
}
.pontuacao{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.quiz-div-end button{
  color:White;
  font-size: 30px;
  margin-top: 20px;
  background-color:#0398DA;
  justify-content: center;
  padding: 8px;
  border: 0 solid white;
  border-radius: 10px;
  text-shadow: 1px 1px black;
}
.quiz-div-end h2{
  margin-top: 50px;
  text-shadow: 1px 1px black;
}
.gabarito{
  display: flex;
  flex-direction: column;
  border: 0 solid white;
  border-radius: 10px;
  background-color: #0398DA;
  padding: 20px 100px 20px 100px;
  width: 40%;
  text-align: center;
  margin-bottom: 30px;
}
.pergunta{
  font-size: 26px;
  text-shadow: 1px 1px black;
  margin-bottom: 10px;
}
.resposta{
  background-color: green;
  border: 3px 3px green;
  border-radius: 10px;
  padding: 20px;
  font-size: 16px;
  text-shadow: 1px 1px black;
  margin-bottom: 20px;
}
.certo{
  background-color: green !important;
}
.errado{
  background-color: red !important;
}

.body{
  width: 100%;
  height: 100%;
}
@media(max-width: 768px){
  .quiz-div p{
    width: 80%;
    text-shadow: none;
  }
  .quiz-div h2{
    font-size: 18px;
    max-width: 60%;
    margin-top: 2vh;
  }
  .gabarito{
    display: flex;
    flex-direction: column;
    border: 0 solid white;
    border-radius: 10px;
    background-color: #0398DA;
    padding: none;
    width: 80%;
    text-align: center;
    margin-bottom: 30px;
    padding: 20px;
  }
  .pergunta{
    font-size: 18px;
    text-shadow: 1px 1px black;
    margin-bottom: 10px;
  }
  .resposta{
    background-color: green;
    border: 3px 3px green;
    border-radius: 10px;
    padding: 20px;
    font-size: 16px;
    text-shadow: 1px 1px black;
    margin-bottom: 20px;
  }
  /*.quiz-page{
    margin-top: 50px;
    margin-bottom: 200px;
  }*/
  .pontuacao{
    align-items: center;
  }
  .pontuacao h1{
    font-size: 2rem;
    text-align: center;
    
  }
  .quiz-div button{
    width: 44%;
  }
}