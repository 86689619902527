.h1-winner {
  background-color: #3949AB;
  width: 100%;
  margin: 15px 0px 25px 0px;
  padding: 10px 0px;
  text-align: center;
  font-size: 4em;
  font-weight: 700;
  color: white;
  opacity: .87;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.h2-winner {
  margin: 0px 0px 25px 0px;
  padding: 0;
  text-align: center;
  font-size: 1.5em;
  font-weight: 500;
  color: #FFEA00;
  opacity: .87;
}

.velha-container {
  position:relative;
  height: 350px;
  max-width: 350px;
  margin: 0 auto;
}



  .board-velha {
    
    
      display: flex;
      width: 80%;
      height: 80%;
      margin: 0px auto;
      padding: 0;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  }

      .square {
          width: 33.333%;
          height: 33.333%;
          margin: 0 auto;
          box-sizing: border-box;
          border: .25px groove black;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
          box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
          transition: all 0.3s cubic-bezier(.25,.8,.25,1);
      }

      .square:hover{
          background-color: #FFE57F;
          cursor: pointer;
          box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
      }

      .playerSymbol {
          font-size: 4em;
          color: #1A237E;
          opacity: .75;
      }

.reset {
  width: 100%;
  height: 100px;
  margin: 25px auto;
  text-align: center;
}

  .resetButton {
      width: 150px;
      height: 35px;
      margin: 0px auto;
      border: 1px solid #FFC107;
      border-radius: 20px;
      background-color: #FFC107;
      text-align: center;
      font-weight: 700;
      font-size: 1em;
      color: white;
      cursor: pointer;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  }

  .resetButton:hover{
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
