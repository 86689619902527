.puzzle-full {
  display: flex;


  align-items: center !important;
  width: 100%;
  height: 100%;
  margin-top: 10px;

}
.puzzle-full-mobile{

  margin-top: 100px;
  margin-bottom: 100px;
}
.puzzle-full div{
 margin: 0 auto;
}
.fundo{
  align-items: center;
  background-color:  #C7F3FF;
}
.puzzletest{
  margin: 0 auto;
  margin-top: 200px;
}
.start{
  height: 100%;
  width: 100%;
}
.start-mobile{
  background-color:  #C7F3FF;
}

@media(max-width: 768px){
  .puzzle-full {
    margin-top: 25px;
  }
}