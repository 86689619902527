.easy-container{
    max-width: 500px;
    max-height: 400px;
    padding: 24px;
    margin: 10px auto;
    border: 1px solid #ccc;
    background: #FFF;
    display: table;
}

.easy-container header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.easy-container .configuration-group{
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.easy-container .configuration-group label{
    color: rgb(73, 73, 73);
    margin-right: 10px;
    font-size: 18;
}

.easy-container .configuration-group input{
    padding: 5px 10px;
    margin-right: 40px;
}


.easy-container button{
    margin: 24px auto 0 auto;
    border: rgb(52, 72, 187) 2px solid;
    background: rgb(52, 115, 187);
    padding: 10px 30px;
    text-align: center;
    color: #fff;
    border-radius: 8px;
    display: block;
}

.easy-container button:hover{
    opacity: 0.8;
}

.easy-container .words{
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
}
.easy-container .words span{
    margin: 0 10px;
    font-size: 24px;
    line-height: 28px;
    cursor: default;
    display: inline-block;
}

.easy-container .words span.finded{
    text-decoration: line-through;
}


